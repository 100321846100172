<template>
    <el-collapse-item :name="itemName">
        <template slot="title">
            <div class="prtf-item-header" v-on:click.stop="">
                <div class="prtf-item-header-l" @click="expand_item">
                    <div class="prtf-item-header-name">{{portfolio.name}}</div>
                    <div class="prtf-item-header-cur">{{portfolio.currency}}</div>

                    <div class="prtf-item-info-panel-bot" v-if="active && (portfolio.date || portfolio.comment)">
                        <div class="prtf-item-header-bot-date" v-if="portfolio.date">{{ portfolio.date.substr(0, 10) }}</div>
                        <div class="prtf-item-header-bot-desc" v-if="portfolio.comment">{{ portfolio.comment }}</div>
                    </div>

                    <el-button class="prtf-item-header-edt-btn" type="text" v-if="active" @click="edit_window_open = true">
                        <svg-icon icon-class="edit" />
                    </el-button>

                    <div class="prtf-item-header-assets-holder" v-if="!active" > 
                        <div class="prtf-item-header-assets" v-if="assets.equity && assets.equity.w">
                            <div class="prtf-item-header-asset-name">Equity</div>
                            <div class="prtf-item-header-asset-val">
                                <FormattedNumber 
                                    :value="assets.equity.w"
                                    :decimals="2"
                                    percents
                                    highlight />
                            </div>
                        </div>
                        <div class="prtf-item-header-assets" v-if="assets.bond && assets.bond.w">
                            <div class="prtf-item-header-asset-name">Fixed Income</div>
                            <div class="prtf-item-header-asset-val">
                                <FormattedNumber 
                                    :value="assets.bond.w"
                                    :decimals="2"
                                    percents
                                    highlight />
                            </div>
                        </div>
                        <div class="prtf-item-header-assets" v-if="assets.cash && assets.cash.w">
                            <div class="prtf-item-header-asset-name">Cash</div>
                            <div class="prtf-item-header-asset-val">
                                <FormattedNumber 
                                    :value="assets.cash.w"
                                    :decimals="2"
                                    percents
                                    highlight />
                            </div>
                        </div>
                        <div class="prtf-item-header-assets" v-if="assets.future && assets.future.w">
                            <div class="prtf-item-header-asset-name">Future</div>
                            <div class="prtf-item-header-asset-val">
                                <FormattedNumber 
                                    :value="assets.future.w"
                                    :decimals="2"
                                    percents
                                    highlight />
                            </div>
                        </div>
                        <div class="prtf-item-header-assets" v-if="assets.option && assets.option.w">
                            <div class="prtf-item-header-asset-name">Option</div>
                            <div class="prtf-item-header-asset-val">
                                <FormattedNumber 
                                    :value="assets.option.w"
                                    :decimals="2"
                                    percents
                                    highlight />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="prtf-item-header-r">
                    <div v-if="active" class="prtf-item-header-btns-group">
                        <!-- <el-button type="danger" class="prtf-item-header-btn-accounts" @click="recalculate">
                            Recalc
                        </el-button> -->
                        <el-button class="prtf-item-header-btn-accounts" @click="open_accounts">
                            Accounts
                        </el-button>
                        <el-button class="prtf-item-header-btn-history" @click="open_history">
                            History
                        </el-button>
                    </div>
                    <el-button  @click="new_operation_select_window_open = true">
                        <svg-icon icon-class="plus" />
                        Add operations
                    </el-button>
                    <el-button class="prtf-item-header-arrow" @click="open_portfolio_tab">
                        <svg-icon icon-class="fullscreen" />
                    </el-button>
                    <el-button v-if="active" class="prtf-item-header-arrow" @click="collapse_item">
                        <i class="el-icon-arrow-up"></i>
                    </el-button>
                    <el-button v-else class="prtf-item-header-arrow" @click="expand_item">
                        <i class="el-icon-arrow-down"></i>
                    </el-button>
                </div>
            </div>
        </template>

        <DataGrid
            :expandable="true"
            :showsold="true"
            :portfolio="portfolio._id"
            :source_list="portfolio.list"
            :source_loading="loading"
            v-if="'list' in portfolio"
            :client="client"
            :transfer_available="transfer_available" />

        <EditWindow 
            :open="edit_window_open" 
            @closed="edit_window_open = false" 
            :client="client"
            :portfolio="portfolio"
            @update-list="update_list" />

        <NewOperationSelectWindow 
            :open="new_operation_select_window_open" 
            @closed="new_operation_select_window_open = false" 
            :client="client"
            :portfolio="portfolio"
            :portfolio_date="date"
            :portfolio_mode="mode" />
        
    </el-collapse-item>
</template>

<script>
import { mapState } from 'vuex'
import FormattedNumber from '@/components/Formats/FormattedNumber'

import DataGrid from './Components/DataGrid'
import EditWindow from './EditWindow'
import NewOperationSelectWindow from './Components/NewOperationSelectWindow'

export default {
    components: { FormattedNumber, DataGrid, EditWindow, NewOperationSelectWindow },
    props: {
        client: {
            required: true,
        },
        portfolio: {
            required: true,
        },
        active: {
            type: Boolean,
        },
        itemName: {
            type: String,
            required:true,
        },
        transfer_available: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            required:true
        },
        date: {
            type: Date,
            required:true
        },
    },

    data(){
        return {
            edit_window_open: false,
            new_operation_select_window_open: false,
            loading:false
        }
    },

    computed: {
        ...mapState({
            user : state => state.app.user,
        }),

        assets(){
            if (this.portfolio.data && this.portfolio.data.assets)
                return this.portfolio.data.assets;
            else
                return {}
        },
    },

    methods: {
        collapse_item(){
            this.$emit('collapse-item', this.itemName)
        },
        expand_item(){
            this.$emit('expand-item', this.itemName)
        },
        update_list(){
            this.$emit('update-list')
        },
        open_accounts(){
            this.$router.push({ path: '/accounts/' + this.client.id + '/' + this.portfolio._id})
        },
        open_history(){
            this.$router.push({ path: '/operations/' + this.client.id + '/' + this.portfolio._id})
        },
        open_portfolio_tab(){
            this.$router.push({ path: '/portfolio/' + this.client.id + '/' + this.portfolio._id})
        },
        recalculate(){
            const recalculate_loading = this.$loading({
                lock: true,
                text: 'Recalculating Portfolio',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('portfolioOperations/recalculate_portfolio', {client_id: this.client._id, portfolio_id: this.portfolio._id}).then(() => {
                recalculate_loading.close();
                this.$emit('refresh')
                this.get_list()
            })
        },
        get_list(){
            let params = {
                portfolio_id : this.portfolio._id,
                client_id    : this.client._id,
                mode         : this.mode,
                date         : this.$moment(this.date).format('YYYY-MM-DD')
            }
            this.loading = true;
            this.$store.dispatch('portfolio/getAnalytics', params).then((response) => {
                this.$set(this.portfolio, 'data', response.data)
                this.$set(this.portfolio, 'list', response.list)
                this.loading = false;
            })
        }
    },

    mounted(){
        this.get_list()
    }
}
</script>
