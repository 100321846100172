<template>
    <el-dialog
        :visible.sync="show_modal"
        width="95%"
        append-to-body
        modal-append-to-body
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :show-close="false">
            
        <template slot="title">
            <div>
                <div class="tl-dialog-title">Excel Import Results</div>
                <div class="tl-dialog-subtitle" v-if="status === 'critical_error'">File contains critical errors and cannot be uploaded</div>
            </div>
        </template>

        <vxe-table
            border
            ref="ImportErrorGrid"
            :data="grid_data"
            class="common-grid" 
            auto-resize
            size="mini"
            :max-height="table_height"
            :cell-class-name="cell_class_name"
            :checkbox-config="{checkField: 'checked', trigger: 'row', checkMethod: checkbox_method}">
            <vxe-column type="checkbox" fixed="left" width="60"></vxe-column>
            <vxe-column field="status" title="Status" fixed="left" width="120"></vxe-column>
            <vxe-column field="trade_date" title="Trade date"  width="120">
                <template #default="{ row }">
                    <FormattedDate :value="row.trade_date" time />
                </template>
            </vxe-column>
            <vxe-column field="value_date" title="Value date" width="120">
                <template #default="{ row }">
                    <FormattedDate :value="row.value_date" />
                </template>
            </vxe-column>
            <vxe-column field="portfolio_raw" title="Portfolio" width="120"></vxe-column>
            <vxe-column field="operation_type_raw" title="Operation type" width="120"></vxe-column>
            <vxe-column field="asset" title="Asset" width="120"></vxe-column>
            <vxe-column field="isin" title="ISIN" width="120"></vxe-column>
            <vxe-column field="ticker" title="Ticker" width="120"></vxe-column>
            <vxe-column field="quantity" title="Quantity" width="120"></vxe-column>
            <vxe-column field="price" title="Price" width="120"></vxe-column>
            <vxe-column field="coupon" title="Coupon" width="120"></vxe-column>
            <vxe-column field="comissions" title="Comissions" width="120"></vxe-column>
            <vxe-column field="strike" title="Strike" width="120"></vxe-column>
            <vxe-column field="maturity" title="Maturity" width="120"></vxe-column>
            <vxe-column field="underlying_isin" title="Underlying ISIN" width="120"></vxe-column>
            <vxe-column field="underlying_ticker" title="Underlying Ticker" width="120"></vxe-column>
            <vxe-column field="gross_amount" title="Gross Amount" width="120"></vxe-column>
            <vxe-column field="net_amount" title="Net Amount" width="120"></vxe-column>
            <vxe-column field="trade_currency" title="Trade Currency" width="120"></vxe-column>
            <vxe-column field="debit_account_raw" title="Debit account" width="120"></vxe-column>
            <vxe-column field="credit_account_raw" title="Credit account" width="120"></vxe-column>
            <vxe-column field="comment" title="Comment" width="120"></vxe-column>
        </vxe-table>

        <div class="tl-form-buttons">
            <div class="spacer"></div>
            <el-button @click="cancel_upload" v-if="status !== 'critical_error'">Cancel</el-button>
            <el-button type="primary" class="btn-auto-width" @click="confirm_upload" v-if="status !== 'critical_error'">Upload selected</el-button>
            <el-button type="primary" class="btn-auto-width" @click="show_modal=false" v-if="status === 'critical_error'">Close</el-button>
        </div>

    </el-dialog>
</template>

<script>
import $ from 'jquery'
import FormattedDate from '@/components/Formats/FormattedDate'

export default {
    components: { FormattedDate },
    props: {
        open: {
            type: Boolean,
            default: false
        },
        client: {
            type: Object,
            required: true,
        },
    },

    data(){
        return {
            show_modal : false,
            grid_data  : [],
            status     : undefined,
            excel_time : undefined,
        }
    },

    computed: {
        table_height(){
            return $(window).height() - 270
        },
    },

    methods: {
        load(import_data) {
            this.grid_data    = import_data.items
            this.status       = import_data.status
            this.excel_time   = import_data.excel_time
        },

        cancel_upload(){
            this.$store.dispatch('portfolioOperations/cancelImport', {client_id: this.client._id, excel_time: this.excel_time}).then((response) => {
                this.show_modal = false
            })
        },

        confirm_upload(){
            let records = this.$refs.ImportErrorGrid.getCheckboxRecords()
            if (records){
                let ids = []
                records.forEach(r => {
                    ids.push(r._id['$oid'])
                })
                let loadingInstanse = this.$loading({
                    lock: true,
                    text: 'Saving operations',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$store.dispatch('portfolioOperations/confirmImport', {client_id: this.client._id, excel_time: this.excel_time, ids: ids}).then((response) => {
                    this.show_modal = false
                    loadingInstanse.close();
                    this.$message.success('Operations saved successfully');
                    this.$emit('refresh')
                })
            }
        },

        checkbox_method ({ row }) {
            return row.status !== 'crit error' && this.status !== 'critical_error'
        },

        cell_class_name({ row, column }){
            if (column.property === 'trade_date' && row.trade_date_error)
                return 'prtf-import-error-cell';
            else if (column.property === 'value_date' && row.value_date_error)
                return 'prtf-import-error-cell';
            else if (column.property === 'portfolio_raw' && row.portfolio_error)
                return 'prtf-import-error-cell';
            else if (column.property === 'operation_type_raw' && row.operation_type_error)
                return 'prtf-import-error-cell';
            else if (column.property === 'isin' && row.isin_error)
                return 'prtf-import-error-cell';
            else if (column.property === 'quantity' && row.quantity_error)
                return 'prtf-import-error-cell';
            else if (column.property === 'price' && row.price_error)
                return 'prtf-import-error-cell';
            else if (column.property === 'trade_currency' && row.trade_currency_error)
                return 'prtf-import-error-cell';

            else if (column.property === 'debit_account_raw' && row.debit_account_error)
                return 'prtf-import-error-cell';
            else if (column.property === 'credit_account_raw' && row.credit_account_error)
                return 'prtf-import-error-cell';

            else if (row.status === 'success')
                return 'prtf-import-success-cell';
            else if (row.status === 'duplicate error')
                return 'prtf-import-warning-cell';
            else if (row.status === 'file duplicate error')
                return 'prtf-import-error-cell';

            return '';
        }
    },

    watch: {
        open(val){
            if (val) {
                this.show_modal = true;
            }
        },
        show_modal(val){
            if (!val) {
                this.$emit('closed');
            }
        },
    }
}
</script>
