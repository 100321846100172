<template>
    <el-dialog
        :visible.sync="show_modal"
        width="500px"
        append-to-body
        modal-append-to-body
        :close-on-click-modal="false"
        :destroy-on-close="true">
            
        <template slot="title"><div class="tl-dialog-title">Select Action</div></template>

        <div class="prtf-import-block">
            <div class="prtf-import-item"><el-button type="primary" plain disabled>Download Template</el-button></div>

            <div class="prtf-import-item">
                <el-upload
                    :action="upload_url"
                    :headers="request_headers"
                    name="upload"
                    :data="request_params"
                    :before-upload="handle_before_upload"
                    :file-list="file_list"
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/xml"
                    ref="upload"
                    :on-success="handle_success"
                    :on-error="handle_error">
                    <el-button type="primary">Upload file</el-button>
                </el-upload>
            </div>
        </div>

    </el-dialog>
</template>

<script>
import Urls from '@/../config/url.js'

export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
        client: {
            type: Object,
            required: true,
        },
    },

    data(){
        return {
            show_modal      : false,
            file_list       : [],
            loadingInstanse : undefined,
        }
    },

    computed: {
        accessToken(){
            return this.$store.getters['app/accessToken']
        },
        upload_url(){
            return Urls.portfolioOperationsImport
        },
        request_headers(){
            let headers = {}
            headers['Authorization'] = 'Bearer ' + this.accessToken
            return headers
        },
        request_params(){
            return { clientid:  this.client._id }
        }
    },

    methods: {
        handle_before_upload(file){
            this.loadingInstanse = this.$loading({
                lock: true,
                text: 'Processing Excel file',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        handle_success(response){
            this.loadingInstanse.close();
            this.$message.success('Excel file processed');
            this.file_list = []
            this.show_modal = false
            this.$emit('upload-finished', response)
        },
        handle_error(){
            this.loadingInstanse.close();
            this.$message.error('An Error Occured While Processing File');
            this.file_list = []
            this.show_modal = false
        }
    },

    watch: {
        open(val){
            if (val) {
                this.show_modal = true;
                this.file_list = []
            }
        },
        show_modal(val){
            if (!val) {
                this.file_list = []
                this.$emit('closed');
            }
        }
    }
}
</script>
