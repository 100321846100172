<template>
    <el-dialog
        :visible.sync="show_modal"
        width="850px"
        append-to-body
        modal-append-to-body
        :close-on-click-modal="false"
        :destroy-on-close="true">

        <template slot="title">
            <div>
                <div class="tl-dialog-title">
                    <span v-if="portfolio._id">Edit portfolio {{portfolio.name}}</span>
                    <span v-else>Add new portfolio</span>
                </div>
                <div class="tl-dialog-subtitle">{{client.first_name}} {{client.last_name}}</div>
            </div>
        </template>

        <el-form 
            label-position="top" 
            label-width="140px" 
            :model="formdata" 
            size="small" 
            :rules="form_rules" 
            autocomplete="off" 
            class="tl-form"
            ref="PortfolioEditor">
            
            <el-form-item label="Portfolio Name" prop="name">
                <el-input v-model="formdata.name" />
            </el-form-item>

            <div class="tl-form-row">
                <el-form-item label="Currency" prop="currency">
                    <el-select v-model="formdata.currency" :disabled="!!formdata._id">
                        <el-option
                            v-for="item in currency_list"
                            :key="item.code"
                            :label="item.code"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Start Date" prop="date">
                    <el-date-picker
                        v-model="formdata.date"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :clearable="false"
                        :disabled="!!formdata._id">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="Accumulated P&L" prop="pl">
                    <el-input v-model="formdata.pl" />
                </el-form-item>
            </div>

            <div class="tl-form-row">
                <el-form-item label="Cash Acc. Method" prop="acc_cash">
                    <el-select v-model="formdata.acc_cash" :disabled="!!formdata._id">
                        <el-option
                            v-for="item in accounting_methods"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Fixed Income Acc. Method" prop="acc_bond">
                    <el-select v-model="formdata.acc_bond" :disabled="!!formdata._id">
                        <el-option
                            v-for="item in accounting_methods"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Equity Acc. Method" prop="acc_eq">
                    <el-select v-model="formdata.acc_eq" :disabled="!!formdata._id">
                        <el-option
                            v-for="item in accounting_methods"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Future Acc. Method" prop="acc_future">
                    <el-select v-model="formdata.acc_future" :disabled="!!formdata._id">
                        <el-option
                            v-for="item in accounting_methods"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Option Acc. Method" prop="acc_future">
                    <el-select v-model="formdata.acc_option" :disabled="!!formdata._id">
                        <el-option
                            v-for="item in accounting_methods"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>

            <el-form-item label="Risk-Free Rate" class="portf-form-benchmark_item">
                <div style="display: flex;align-items: flex-end;margin-bottom:16px">
                    <el-radio-group v-model="risk_free_rate_type" size="small">
                        <el-radio label="calc" value="calc">Calculated Risk-Free Rate</el-radio>
                        <el-radio label="fixed" value="fixed">Fixed Risk-Free Rate</el-radio>
                    </el-radio-group>
                    <div v-if="risk_free_rate_type === 'fixed'" style="margin-left:24px">
                        <el-input-number
                        size="mini"
                        v-model="formdata.risk_free_rate_set"
                        :controls="false"
                        :step="0.1">
                        </el-input-number>
                    </div>
                </div>
                <div v-if="risk_free_rate_type === 'calc'">
                    <BenchRow
                        v-for="(rate, index) in formdata.risk_free_rate"
                        :value="rate"
                        :key="index"
                        :options="risk_free_rates_list"
                        @remove-value="remove_bench_value(index, 'risk_free_rate')" />
                    <div class="portf-edit-rfr_calc-btn" @click="add_bench_value('risk_free_rate')">
                        <i class="el-icon-circle-plus-outline"></i> index
                    </div>
                </div>
            </el-form-item>

            <el-form-item label="Benchmarks" prop="benchmarks" class="portf-form-benchmark_item">
                <BenchRow
                    v-for="(benchmark, index) in formdata.benchmarks"
                    :value="benchmark"
                    :key="index"
                    :options="benchmarks_list"
                    @remove-value="remove_bench_value(index, 'benchmarks')" />
                <div class="portf-edit-rfr_calc-btn" @click="add_bench_value('benchmarks')">
                    <i class="el-icon-circle-plus-outline"></i> index
                </div>
            </el-form-item>

            <el-form-item label="Comment" prop="comment">
                <el-input v-model="formdata.comment" placeholder="Comment" />
            </el-form-item>
        </el-form>

        <div class="tl-form-buttons">
            <el-button v-if="Object.keys(portfolio).length && portfolio._id" type="danger" plain  @click="delete_record">Delete</el-button>
            <div class="spacer" />
            <el-button @click="show_modal = false">Cancel</el-button>
            <el-button type="primary" @click="save_record">Save</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BenchRow from './Components/EditWindowBenchRow'

export default {
    components: {BenchRow},
    props: {
        open: {
            type: Boolean,
            default: false
        },
        client: {
            type: Object,
            required: true,
        },
        portfolio: {
            type: Object,
            required: false,
            default () { return {} }
        },
    },

    data(){
        return {
            show_modal          : false,
            formdata            : {},
            form_rules          : {
                name     : [{ required: true, message: 'Please input Portfolio name', trigger: 'blur' }],
                currency : [{ required: true, message: 'Please select Portfolio currency', trigger: 'blur' }],
                acc_cash : [{ required: true, message: 'Please select Accounting method', trigger: 'blur' }],
                acc_eq   : [{ required: true, message: 'Please select Accounting method', trigger: 'blur' }],
                acc_bond : [{ required: true, message: 'Please select Accounting method', trigger: 'blur' }],
                acc_future : [{ required: true, message: 'Please select Accounting method', trigger: 'blur' }],
                acc_option : [{ required: true, message: 'Please select Accounting method', trigger: 'blur' }],
            },
            risk_free_rate_type : '',
            accounting_methods: [
                {label:'FIFO',         value:'fifo',   disabled: false},
                {label:'LIFO',         value:'lifo',   disabled: true},
                {label:'Average Cost', value:'avcost', disabled: true},
            ]
        }
    },

    computed: {
        ...mapState({
            user                 : state => state.app.user,
            currency_list        : state => state.currency.list,
            risk_free_rates_list : state => state.sourcesRiskFreeRatesCodes.list,
            benchmarks_list      : state => state.sourcesBenchmarksCodes.list
        }),
    },

    methods: {
        save_record(){
            let $this = this;
            $this.$refs.PortfolioEditor.validate((valid) => {
                if (valid) {
                    $this.$refs.PortfolioEditor.clearValidate();
                    $this.editWindow = false;
                    if ($this.formdata._id) {
                        $this.$store.dispatch('portfolio/updateItem', $this.formdata).then(() => {
                            $this.$emit('update-list');
                            $this.show_modal = false;
                        });
                    }
                    else {
                        $this.$store.dispatch('portfolio/addItem', $this.formdata).then(() => {
                            $this.$emit('update-list');
                            $this.show_modal = false;
                        });
                    }
                } else {
                    return false;
                }
            });
        },

        delete_record(){
            this.$confirm('This will permanently delete portfolio and operations. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('portfolio/deleteItem', this.formdata._id).then(() => {
                    this.$emit('update-list');
                    this.show_modal = false;
                });
            }).catch(() => {});
        },

        add_bench_value(field){
            this.formdata[field].push({
                ind_id: undefined,
                ind_percent: 1,
            })
        },
        remove_bench_value(index, field){
            this.formdata[field].splice(index, 1)
        },
    },

    created() {
        this.risk_free_rate_type = '';
    },

    watch: {
        risk_free_rate_type: function (val){
            if(val == 'calc'){
                this.formdata.risk_free_rate_set = null
            } else if(val == 'fixed') {
                this.formdata.risk_free_rate = []
            }
        },
        open(val){
            if (val){
                this.$store.dispatch('sourcesRiskFreeRatesCodes/getList');
                this.$store.dispatch('sourcesRiskFreeRatesFields/getList');
                this.$store.dispatch('sourcesBenchmarksCodes/getList');
                this.$store.dispatch('sourcesBenchmarksFields/getList');

                this.show_modal = true;
                this.risk_free_rate_type = '';
                if (this.$refs.PortfolioEditor) this.$refs.PortfolioEditor.clearValidate();
                this.formdata = {};

                if (this.portfolio._id) {
                    this.formdata = Object.assign({}, this.portfolio);
                    delete this.formdata.list
                    delete this.formdata.data

                    
                    if (this.formdata.risk_free_rate_set)
                        this.risk_free_rate_type = 'fixed';
                    else if (this.formdata.risk_free_rate)
                        this.risk_free_rate_type = 'calc';
                }
                else {
                    this.formdata = Object.assign({}, JSON.parse(JSON.stringify({
                        client_id  : this.client._id,
                        acc_cash   : 'fifo',
                        acc_bond   : 'fifo',
                        acc_eq     : 'fifo',
                        acc_future : 'fifo',
                        acc_option : 'fifo',
                    })));
                }

                if (!this.formdata.risk_free_rate)
                    this.$set(this.formdata, 'risk_free_rate', []);

                if (!this.formdata.benchmarks)
                    this.$set(this.formdata, 'benchmarks', []);
            }
        },
        show_modal(val){
            if (!val) this.$emit('closed');
        }
    }
}
</script>
