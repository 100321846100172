<template>
    <div class="prtf-holder" v-loading="loading">
        <div class="prtf-hdr" :class="{ 'prtf-hdr--hidden': !show_header }">
            <div class="prtf-hdr-l">
                <date-picker
                    v-model="portfolio_date"
                    :lang="lang"
                    type="date"
                    format="DD.MM.YY"
                    value-type="date"
                    placeholder="Date"
                    time-title-format="YYYY-MM-DD ddd"
                    :clearable="false"
                    @change="change_date">
                </date-picker>
                <el-select v-model="portfolio_mode" @change="change_mode">
                    <el-option label="By value date" value="value"></el-option>
                    <el-option label="By trade date" value="trade"></el-option>
                </el-select>
            </div>
            <div class="prtf-hdr-r">
                <!-- <el-button type="danger"  @click="recalculate">Recalc all</el-button> -->
                <el-button @click="import_window_open = true">Import</el-button>
                <!-- <el-button>Export</el-button> -->
                <el-button type="primary" class="prtf-add-port-btn" @click="edit_window_open = true">
                    <svg-icon icon-class="plus" /> 
                    New portfolio
                </el-button>
                <el-button type="primary" plain class="prtf-collapse-btn" @click="collapse_all" v-if="!can_be_expanded">
                    <svg-icon icon-class="double_chevron_big_up" /> 
                    Collapse all
                </el-button>
                <el-button type="primary" plain class="prtf-collapse-btn" @click="expand_all" v-if="can_be_expanded">
                    <svg-icon icon-class="double_chevron_big_down" /> 
                    Expand all
                </el-button>
            </div>
        </div>
        <div class="prtf-body" @scroll='handle_body_scroll'>
            <el-collapse v-model="active_items">
                <Summary 
                    :active="is_item_active('summary')" 
                    item-name="summary" 
                    @collapse-item="collapse_item"
                    @expand-item="expand_item"
                    :client="client"
                    ref="PortfolioSummary"
                    :portfolio_date="portfolio_date"
                    :portfolio_mode="portfolio_mode" />
                <Portfolio
                    v-for="portfolio in portfolio_list"
                    :key="portfolio._id"
                    :active="is_item_active(portfolio._id)"
                    :item-name="portfolio._id"
                    @collapse-item="collapse_item"
                    @expand-item="expand_item"
                    @update-list="refresh"
                    @refresh="refresh"
                    :client="client"
                    :portfolio="portfolio"
                    :transfer_available="transfer_available"
                    :mode="portfolio_mode"
                    :date="portfolio_date"
                    ref="Portfolio"  />
            </el-collapse>
        </div>

        <EditWindow 
            :open="edit_window_open" 
            @closed="edit_window_open = false" 
            @update-list="get_list"
            :client="client" />

        <ImportSelectWindow
            :open="import_window_open"
            @closed="import_window_open = false"
            :client="client"
            @upload-finished="handle_upload_finished" />


        <ImportResultWindow 
            :open="import_result_window_open" 
            @closed="import_result_window_open = false"
            :client="client"
            ref="ImportErrorWindow"
            @refresh="refresh" />
    </div>
</template>

<script>
import Summary from './Portfolio/Summary'
import Portfolio from './Portfolio/'
import EditWindow from './Portfolio/EditWindow'
import en from 'vue2-datepicker/locale/en'
import ImportSelectWindow from './Portfolio/Components/ImportSelectWindow'
import ImportResultWindow from './Portfolio/Components/ImportResultWindow'

export default {
    name: 'portfolio',
    components: { Summary, Portfolio, EditWindow, ImportSelectWindow, ImportResultWindow },

    props: {
        client: {
            required: true,
        },
    },

    data(){
        return {
            show_header              : true,
            last_scroll_position     : 0,
            active_items             : ['summary'],
            edit_window_open         : false,
            portfolio_list           : [],
            portfolio_date           : new Date,
            portfolio_mode           : 'trade',
            loading                  : false,
            import_window_open       : false,
            import_result_window_open : false,
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthFormat: 'MMMM',
                days: en.formatLocale.weekdaysShort
            },
        }
    },

    computed: {
        can_be_expanded(){
            return this.active_items.length == 0
        },
        transfer_available(){
            return (this.portfolio_list.length > 1) ? true: false;
        }
    },

    methods: {
        collapse_all(){
            this.active_items = [];
        },
        expand_all(){
            this.active_items = ['summary'];
            this.portfolio_list.forEach(portfolio => {
                this.active_items.push(portfolio._id)
            })
        },
        collapse_item(name){
            this.active_items = this.active_items.filter(item => { return item !== name })
        },
        expand_item(name){
            this.active_items.push(name)
        },
        handle_body_scroll(e){
            const currentScrollPosition = e.srcElement.scrollTop
            if (currentScrollPosition < 0) {
                return
            }
            if (Math.abs(currentScrollPosition - this.last_scroll_position) < 10) {
                return
            }
            this.show_header = currentScrollPosition < this.last_scroll_position
            this.last_scroll_position = currentScrollPosition
        },
        change_date(){
            this.get_list();
        },
        change_mode(){
            this.get_list();
        },
        is_item_active(id){
            return this.active_items.includes(id);
        },
        get_list(){
            this.loading = true
            this.portfolio_list = []
            return this.$store.dispatch('portfolio/getList', {
                client_id : this.client._id,
                mode      : this.portfolio_mode,
                date      : this.portfolio_date
            }).then((response) => {
                this.portfolio_list = response
                this.loading = false
            })
        },
        recalculate(){
            const recalculate_loading = this.$loading({
                lock: true,
                text: 'Recalculating Portfolios',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('portfolioOperations/recalculate', {client_id: this.client._id}).then(() => {
                recalculate_loading.close();
                this.refresh();
            })
        },

        refresh(){
            this.$refs.PortfolioSummary.get_summary();
            this.get_list().then(() => {
                this.$refs.Portfolio.forEach( p => {
                    p.get_list();
                })
            });
        },

        handle_upload_finished(import_data){
            this.import_result_window_open = true
            this.$refs.ImportErrorWindow.load(import_data)
        }
    },

    mounted(){
        this.get_list();
    }
}
</script>
